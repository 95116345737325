<template>
  <b-card class="shop-notifications">
    <b-row>
      <b-col cols="12">
        <good-data-table
          ref="notificationsRef"
          :columns="notificationsColumns"
          :total-column="'response.responseData.notification.total'"
          :data-column="'response.responseData.notification.data'"
          :api-endpoint="'/shop/notification'"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard } from 'bootstrap-vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import { showErrorNotification } from '@/@core/comp-functions/ui/app'
import { useStoreUi } from './useStore'

export default {
  components: {
    GoodDataTable,
    BRow,
    BCol,
    BCard,
  },
  data() {
    return {
      storeNotifications: [],
      notificationsColumns: [
        {
          label: 'Service Type',
          field: 'service',
        },
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Time',
          field: 'time_at',
        },
        {
          label: 'Status',
          field: 'status',
          align: 'center',
          useResolver: true,
          renderer: props => props.status.toUpperCase(),
        },
        {
          label: 'Description',
          field: 'descriptions',
        },
        {
          label: 'Is Viewed',
          field: 'is_viewed',
          useResolver: true,
          renderer: props => (props.is_viewed ? 'Yes' : 'No'),
        },
      ],
    }
  },
  mounted() {
    this.getNotifications()
  },
  methods: {
    getNotifications() {
      const { shopNotifications } = useStoreUi()

      shopNotifications()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.storeNotifications = data.responseData
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style scoped>
.shop-nav-items .feather-icon-wrapper {
  margin: 0px 10px;
  position: relative;
}
</style>
